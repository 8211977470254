<template>
  <div class='float-l '>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
  //       yData: ["2.02", "64.2", "175.7", "84.5","10.1"],
		// xData: ["博士", "硕士", "本科", "专科", "其他"],
		yData: [],
		xData: []
      },
	  academicdegree:[]
    }
	
  },
  components: {
    Chart,
  },
  mounted () {
	  this.getAcademicdegree()
  },
  methods: {
	  getAcademicdegree(){
		  let that = this
		  this.$axios({
		  		method: 'post',
		  		url: this.url+'/api/academicdegree/getList',
		  		// url: '/api/academicdegree/getList',
		  	})
		  	.then(function(res) {
		  		// console.log(res)
		  		that.academicdegre = res.data.data
		  		// console.log(that.academicdegre)
		  		that.academicdegre.forEach((item) => {
		  			// console.log(item.cname)
		  			if(item.status ==1) {
		  				that.cdata.xData.push(item.cname);
		  				that.cdata.yData.push(item.talents);
		  			}
		  		});
		  		// console.log(that.cdata)
		  	});
	  }
  }
}
</script>

<style lang="scss" scoped>
	
</style>