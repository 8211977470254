<template>
	<div>
		<Echart :options="options" id="centreLeft1Chart1" height="220px" width="477px"></Echart>
	</div>
</template>

<script>
	import Echart from '@/common/echart'
	export default {
		data() {
			return {
				options: {},
				start: 0,
				end: 3
			};
		},
		components: {
			Echart,
		},
		props: {
			cdata: {
				type: Object,
				default: () => ({})
			},
		},
		watch: {
			//  cdata: {
			//    handler (newData) {
			//      this.options = {
			//  tooltip: {
			// trigger: 'axis',
			// axisPointer: {
			//   type: 'shadow'
			// },
			// formatter: "{b}:{c}万"
			//  },
			//  grid: {
			// top: '10%',
			// left: '10%',
			// right: '10%',
			// bottom: '10%',
			// containLabel: true
			//  },
			//  xAxis: {
			// show: false,
			// axisLabel: {
			//   formatter: '{value} 万',
			//   show: true
			// },
			// splitLine: { show: false },
			// axisLine: {
			//   show: true
			// }
			//  },
			//  yAxis: {
			// type: 'category',
			// inverse: true, // 倒叙
			// axisLabel: {
			//   color: '#1E90FF',
			//   fontSize: 16,
			//   formatter: (val) => {
			// 	return `${val}`;
			//   }
			// },
			// axisLine: {
			//   show: false // 轴线
			// },
			// axisTick: {
			//   show: false,// 刻度线
			// },
			// data: newData.xData
			//  },
			//  series: [
			// {
			//   name: 'Echarts',
			//   type: 'bar',
			//   barWidth: 20,
			//   showBackground: true,
			//   barMaxWidth: 20,
			//   barMinWidth: 5,
			//   itemStyle: {
			// 	normal: {
			// 		label:{
			// 			show:true,
			// 			borderRadius: [0, 10, 10, 0],
			// 			position: 'right',
			// 			barBorderRadius: 5,
			// 			color: '#fff',
			// 			fontSize : 13,
			// 			formatter: "{c}万"
			// 		},
			// 	}
			//   },
			//   data: newData.yData
			// }
			//  ]


			//      }
			//    },
			//    immediate: true,
			//    deep: true
			//  }
		},
		created() {
			this.dingshi();
		},
		mounted() {
			this.heng();
			// this.dingshi();
		},
		methods: {
			heng: function() {
				let newData = this.cdata;
				let chartDom = document.getElementById('centreLeft1Chart1');
				let myChart = this.$echarts.init(chartDom);
				this.options = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: "{b}:{c}万"
					},
					grid: {
						top: '10%',
						left: '10%',
						right: '10%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: {
						show: false,
						axisLabel: {
							formatter: '{value} 万',
							show: true
						},
						splitLine: {
							show: false
						},
						axisLine: {
							show: true
						}
					},
					yAxis: {
						type: 'category',
						inverse: true, // 倒叙
						axisLabel: {
							color: '#1E90FF',
							fontSize: 16,
							formatter: (val) => {
								return `${val}`;
							}
						},
						axisLine: {
							show: false // 轴线
						},
						axisTick: {
							show: false, // 刻度线
						},
						data: newData.xData
					},
					dataZoom: {
					            type: 'inside',     // inside: 表示用内测滑块
					            startValue: this.start,     // 开始显示的数
					            endValue: this.end,       // 结束显示的数
					            yAxisIndex: [0],    // 代表是作用在y轴上的
					            // start: '10',
					            // end: '1'
					            // zoomLock: true,
					            zoomOnMouseWheel: false,  // 关闭滚轮缩放
					            moveOnMouseWheel: true, // 开启滚轮平移
					            moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
					
					          },
					series: [{
						name: 'Echarts',
						type: 'bar',
						barWidth: 20,
						showBackground: true,
						barMaxWidth: 20,
						barMinWidth: 5,
						itemStyle: {
							normal: {
								label: {
									show: true,
									borderRadius: [0, 10, 10, 0],
									position: 'right',
									barBorderRadius: 5,
									color: '#fff',
									fontSize: 13,
									formatter: "{c}万"
								},
							}
						},
						data: newData.yData
					}]
				}
				myChart.setOption(this.options);
			},
			dingshi() {
				let that = this;
				setInterval(function() {
					if (that.end == that.cdata.yData.length) {
						that.start = 0;
						that.end = 3;
					} else {
						that.start = that.start + 1;
						that.end = that.end + 1;
					}
					that.heng();
				}, 3000)
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
