<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex" v-for="(item,index) in tIetms" :key="index">
          <span class="fs-xl text mx-2" v-if="index == 0">{{item.item_title}}</span>
        </div>
      </div>
      <div>
        <CenterChart />
      </div>
	  <div>
	  		<!-- <BottomRightChart /> -->
	  </div>
    </div>
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChart'
// import BottomRightChart from '@/components/echart/bottom/bottomRightChart'
export default {
  components: {
    CenterChart
  },
  data(){
	  return {
		  titles:[]
	  }
  },
  props:["tIetms"],
  mounted() {
  	// this.getTitle()
  },
  methods:{
	  getTitle() {
		  // this.titles = this.tIetm
		  // console.log(this.titles,"子组件")
	  }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 520px;
$box-width: 100%;
#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
	font-size: 23px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
