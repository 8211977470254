<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">{{ bigDataTitle }}</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
 
        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text">{{ ccname }}</span>
            </div>
            <div class="react-left ml-3">
              <span class="text">{{ ccname }}</span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b">{{ ccname }}</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>
		<div class="body-box">
			<div>
			   <Top />
			 </div>
		</div> 
		<div class="body-box">
		    <dv-border-box-8>
		      <Center :tIetms="title"/>
		    </dv-border-box-8>
		</div> 
        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-8>
                <bottomLeft :tIetms="title"/>
              </dv-border-box-8>
            </div>
            <div>
              <dv-border-box-8 :reverse="true">
                <!-- <centerRight1 :tIetms="title"/> -->
                <bottomCenter1 :tIetms="title"/>
              </dv-border-box-8>
            </div>
            <div>
              <dv-border-box-8>
                <bottomCenter2 :tIetms="title"/>
              </dv-border-box-8>
            </div>
          
			  <div>
			     <dv-border-box-8 :reverse="true">
			       <bottomRight :tIetms="title"/>
			     </dv-border-box-8>
			   </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'

import Top from './top'
import Center from './center'
import bottomLeft from './bottomLeft'
import bottomCenter1 from './bottomCenter1'
import bottomCenter2 from './bottomCenter2'
import bottomRight from './bottomRight'

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
	  talent:[],
	  title:[],
	  ccname:'',
	  bigDataTitle:''
    }
  },
  components: {
	Top,
    Center,
	bottomLeft,
	bottomCenter1,
	bottomCenter2,
	bottomRight
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
	this.getItemcategory()
	this.getCname()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
	getItemcategory() {
		let that = this
		this.$axios({
				method: 'post',
				url: this.url+'/api/itemcategory/getList',
			})
			.then(function(res) {
				// console.log(res)
				that.title = res.data.data
				// console.log(that.title,"title")
			});
	},
	getCname(){
		let that = this
		this.$axios({
			methods: 'get',
			// https://auth.fjrcy.com//cname
			url: this.url+'//default',

		}).then(function(res) {
			// console.log(res,"default")
			that.ccname = res.data.data.cname
			that.bigDataTitle = res.data.data.bigDataTitle
			// console.log(that.ccname)
		})
	},
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
