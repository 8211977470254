<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
		
        // xData: ["50以上", "40-50", "30-40", "25-30", "25以下"],
        // yData: ["33.8", "64.2", "162.2", "60.8", "16.9"],
		xData: [],
		yData: []
      },
	  agebracket:[]
    }
  },
  components: {
    Chart,
  },
  mounted () {
	  this.getAgebracket()
  },
  methods: {
	  getAgebracket(){
	  		  let that = this
	  		  this.$axios({
	  		  		method: 'post',
	  		  		url: this.url+'/api/agebracket/getList',
					// url: '/api/agebracket/getList'
	  		  	})
	  		  	.then(function(res) {
	  		  		// console.log(res)
	  		  		that.agebracket = res.data.data
	  		  		// console.log(that.agebracket)
	  		  		that.agebracket.forEach((item) => {
	  		  			// console.log(item.cname)
	  		  			if(item.status ==1) {
	  		  				that.cdata.xData.push(item.cname);
	  		  				that.cdata.yData.push(item.talents);
	  		  			}
	  		  		});
	  		  		// console.log(that.cdata)
	  		  	});
	  }
  }
}
</script>

<style lang="scss" scoped>
</style>