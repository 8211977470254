<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        // xData: ["15年以上", "8-15年", "3-8年", "1-3年"],
		xData: [],
        seriesData: [
			// { value: 118.3,name: "15年以上"},
			// { value: 104.7,name: "8-15年"},
			// { value: 74.3,name: "3-8年"},
			// { value: 40.5,name: "1-3年"}
        ]
      },
	  workingyears:[]
    }
  },
  components: {
    Chart,
  },
  mounted () {
	  this.getWorkingyears()
  },
  methods: {
	  getWorkingyears() {
	  	let that = this
	  	this.$axios({
	  			method: 'post',
	  			url: this.url+'/api/workingyears/getList',
	  			// url: '/api/workingyears/getList'
	  		})
	  		.then(function(res) {
	  			// console.log(res)
				that.workingyears = res.data.data
				// console.log(that.workingyears)
				that.workingyears.forEach((item) => {
					// console.log(item.cname,index)
					if(item.status ==1) {
						that.cdata.xData.push(item.cname);
						that.cdata.seriesData.push({
							value:item.talents,
							name:item.cname
						});
					}
				});
				// console.log(that.cdata)
	  		});
	  },
  }
}
</script>

<style lang="scss" scoped>
</style>