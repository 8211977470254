
<template>
  <div id="bottomCenter2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex" v-for="(item,index) in tIetms" :key="index">
          <span class="fs-xl text mx-2" v-if="index == 3">{{ item.item_title }}</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
		<BottomCenter2Chart />
      </div>
    </div>
  </div>
</template>

<script>
import BottomCenter2Chart from '@/components/echart/bottomCenter2/bottomCenter2Chart'
export default {
  components:{
	  BottomCenter2Chart
  },
  props:["tIetms"]
}
</script>

<style lang="scss" scoped>
$box-height: 300px;
$box-width: 471px;
#bottomCenter2 {
  // padding: 16px;
  // padding-top: 20px;
  padding: 20px 10px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
	font-size: 23px;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 470px;
      height: 300px;
    }
  }
}
</style>
