<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        // xData: ["200万以上", "100-200万", "50-100万", "30-50万", "10-30万","10万以下"],
		xData: [],
        seriesData: [
			// {
			//     name: '200万以上',
			//     value: 6.1
			//   },
			//   {
			//     name: '100-200万',
			//     value: 24.3
			//   },
			//   {
			//     name: '50-100万',
			//     value: 98
			//   },
			//   {
			//     name: '30-50万',
			//     value: 131.8
			//   },
			//   {
			//     name: '10-30万',
			//     value: 50.7
			//   },
			//   {
			//     name: '10万以下',
			//     value: 27
			//   }
        ]
      },
	  salarysegment:[]
    }
  },
  components: {
    Chart,
  },
  mounted () {
	  this.getSalarysegment()
  },
  methods: {
	  getSalarysegment() {
	  	let that = this
	  	this.$axios({
	  			method: 'post',
	  			url: this.url+'/api/salarysegment/getList',
	  			// url: '/api/salarysegment/getList'
	  		})
	  		.then(function(res) {
	  			// console.log(res)
	  				that.salarysegment = res.data.data
	  				// console.log(that.salarysegment)
	  				that.salarysegment.forEach((item) => {
	  					// console.log(item.cname,index)
	  					if(item.status ==1) {
	  						that.cdata.xData.push(item.cname);
	  						that.cdata.seriesData.push({
	  							value:item.talents,
	  							name:item.cname
	  						});
	  					}
	  				});
	  				// console.log(that.cdata)
	  		});
	  }
  }
}
</script>

<style lang="scss" scoped>
</style>