<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          // "石油化工",
          // "新能源",
          // "机械设备",
          // "新材料",
          // "工业自动化",
          // "消费品",
          // "综合",
          // "房地产",
          // "制药医疗"
        ],
        barData: [
          // 43.9,
          // 40.5,
          // 6.76,
          // 60.8,
          // 16.9,
          // 50.7,
          // 57.4,
          // 50.7,
          // 10.14
        ],
        rateData: [],
		inslist:[]
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    // this.setData();
	this.getIndustrytalents()
  },
  // watch:{
	 //  cdata(){
		//   console.log(this.cdata,"1111")
		//   this.getIndustrytalents()
	 //  }
  // },
  methods: {
    // 根据自己的业务情况修改
    setData () {
		// for (let i = 0; i < this.cdata.barData.length -1; i++) {
		//   let rate = 0;
		//   this.cdata.rateData.push(rate.toFixed(2));
		// }
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
	
	getIndustrytalents () {
		let that = this
		this.$axios({
				method: 'post',
				url: this.url+'/api/industrytalents/getList',
				// url: '/api/industrytalents/getList'
			})
			.then(function(res) {
				// console.log(res.data.data)
				that.inslist = res.data.data
				that.inslist.forEach((item) => {
					// console.log(item.cname,index)
					if(item.status ==1) {
						that.cdata.category.push(item.cname);
						that.cdata.barData.push(item.talents);
					}
				});
				// console.log(that.cdata)
				// console.log(that.cdata.barData)
				// console.log(that.cdata.category)
			});
	}
	
	
	
	
	
	
	
	
  }
};
</script>

<style lang="scss" scoped>
</style>