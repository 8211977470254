<template>
  <div>
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="480px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
				
              }
            },
			formatter: "{b}:{c}万"
          },
          legend: {
            data: ["人才储备"],
            textStyle: {
              color: "#B4B4B4",
			  fontSize: 16
            },
            top: "0%"
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%"
          },
          xAxis: {
            data: newData.category,
			axisLabel: {
			  color: '#1E90FF',
			  fontSize: 20
			},
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            }
          },
          yAxis: [
            {
				show: false,
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              }
            }
          ],
          series: [
            {
              name: "人才储备",
              type: "bar",
              barGap: "100%",
              barWidth: 70,
              itemStyle: {
                normal: {
					label:{
						show:true,
						position: 'top',
						barBorderRadius: 5,
						color: 'orange',
						fontSize : 18,
						formatter: "{c}万"
					}
                }
			  },
              z: -12,
              data: newData.barData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>